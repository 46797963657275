.staffcont {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    padding: 20px;
}

.staffcont .stimg .card-img-top {
    max-width: 200px;
    min-width: 200px;
    height: 220px;
    object-fit: cover;
}