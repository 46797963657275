.card-group {
    margin-top: 40px;
}

h3 {
    margin-top: 40px;
    color: black;
}

.prodcont1111 .col1 {
    max-width: 300px;
    min-width: 150px;
}

.prodcont1111 {
    background-color: rgb(245, 245, 245);
    border: 4px solid rgba(10, 37, 77, 0.566);
    border: none;
    border-radius: 0px;
}

.prodcont1111 .icon {
    width: 50px;
    max-height: 50px;
    margin-top: 10px;
}

.prodcont1111 .weaponry .col1 {
    max-width: 200px;
    min-width: 150px;
}

.col1 .card {
    border: none;
    background-color: rgb(245, 245, 245);
    text-align: center;
    display: flex;
    align-items: center;
}

.weaponry .h3, .optics .h3 {
    text-align: center;
    background-color: white;
    padding-top: 1px;
    padding-bottom: 10px;
}

.g-3 {
    display: flex;
    justify-content: center;
}

.g-3 .g-4 {
    width: 82%;
}

.optics .row {
    margin-left: 0;
}

.prodcont1111 .row-cols-md-2 {
    margin-left: 0;
}