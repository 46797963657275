.prodcont_1 {
    border: none;
    background-color: rgb(245, 245, 245);
}

.icon {
    width: 50px;
    max-height: 50px;
}

.prodcont_1 .card {
    text-align: start;
}

.prodcont_1 .card img {
    margin-left: 16px;
}

.prodcont_1 .col1 {
    max-width: 300px;
    min-width: 150px;
}

.prodcont_1 .weaponry .col1 {
    max-width: 200px;
    min-width: 150px;
}