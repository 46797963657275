.page-footer {
    background-color: rgba(28,49,74);
    color: white;
    padding: 0;
    margin-top: 30px;
}

.col-md-6 h5 {
    margin-left: 0;
    margin-bottom: 20px;
    text-align: start;
}
